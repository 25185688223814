import { ReactComponent as PdfIcon } from '@/assets/icons/media/pdf.svg'
import { ReactComponent as WordIcon } from '@/assets/icons/media/docx.svg'
import { ReactComponent as ExcelIcon } from '@/assets/icons/media/excel.svg'
import { ReactComponent as SmallFolderIcon } from '@/assets/icons/utils/smallFolder.svg'

import { ReactComponent as EditIcon } from '@/assets/icons/utils/edit.svg'

import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import TextInput from '@/common/TextInput'
import Modal, { RefType } from '@/common/Modal'
import React, { useState } from 'react'

import { GetFoldersResDTO } from '@/apis/dataRoomApis'
import moment from 'moment'
import { ReactComponent as RedBinIcon } from '@/assets/icons/utils/redBin.svg'

import { useDeleteDocument, useUpdateDocument } from '@/store/dataRoomStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { ToastNotify } from '@/common/toastManager'

import { CREATE_DOCUMENT, GET_FILES } from '@/apis/endpoints'

import useDownload, { useDownloadFileFromStore } from '@/hooks/useDownload'

import { formatFileSize } from '@/helpers/numberFormatter'
import { BASE_API_URL } from '@/constants/api'
import { useLocation } from 'react-router'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

function File({
  id,
  name: fileName,
  updated,
  file,
  metadata,
  invalidateKeys = [],
}: GetFoldersResDTO & { invalidateKeys?: string[] }) {
  //separating filename and its extention
  let newFileName = ''
  if (fileName) {
    const fname = fileName?.split('.')
    newFileName = fname[0]
  }
  const { state } = useLocation()

  const { exportHandler, downloadState } = useDownloadFileFromStore()

  const modalRef = React.useRef<RefType>(null)

  const modalRef2 = React.useRef<RefType>(null)

  const [name, setname] = useState<string>(newFileName as string)
  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  const toggleModal2 = (): void => {
    modalRef2?.current?.handleToggle()
  }

  let icon: JSX.Element = <></>
  let fileExtention = ''
  switch (metadata?.type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = <WordIcon />
      fileExtention = 'docx'
      break
    case 'application/msword':
      icon = <WordIcon />
      fileExtention = 'doc'
      break
    case 'application/pdf':
      icon = <PdfIcon />
      fileExtention = 'pdf'
      break

    default:
      icon = <ExcelIcon />
      fileExtention = 'xlsx'
      break
  }

  const handleDownloadDoc = async () => {
    exportHandler(`${file}`, {
      file_name: newFileName,
      file_type: fileExtention,
    })
  }

  //delete doc
  const {
    isLoading: isDeleteLoading,
    mutateAsync: DeleteFile,
    isError: isDeleteError,
  } = useDeleteDocument([[GET_FILES], [CREATE_DOCUMENT], invalidateKeys])
  const handleDeleteDoc = async () => {
    const { response, error } = await withAsync(() =>
      DeleteFile({
        id,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal2()
    }
  }

  //update doc
  const {
    isLoading: isUpdateDocLoading,
    mutateAsync: UpdateDoc,
    isError: isUpdateDocError,
  } = useUpdateDocument([[GET_FILES]])

  const handleUpdateDoc = async () => {
    const { response, error } = await withAsync(() =>
      UpdateDoc({
        id,
        name,
        fund: state?.id,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal()
    }
  }

  return (
    <main>
      <div className='flex w-full flex-col gap-2 justify-between align-center bg-white rounded-md border h-auto p-2 sm:flex-row sm:h-[64px] sm:gap-0'>
        {/* items on the left */}
        <div className='flex gap-2 items-start'>
          {icon}
          <div className='flex flex-col gap-1'>
            <span className='flex items-center'>
              <Typography label='md'>{newFileName}</Typography>
              <Typography label='md' className='!text-black-white-shades-3'>
                .{fileExtention}
              </Typography>
            </span>
            <span className='flex items-center gap-2'>
              <Typography label='sm' className='!text-black-white-shades-4'>
                Last Modified
              </Typography>
              <div className='w-[3px] h-[3px] rounded-full bg-black-white-shades-4' />
              <Typography label='md' className='!text-black-white-shades-4'>
                {moment(updated).format('Do MMMM YYYY')}
              </Typography>
            </span>
          </div>
        </div>

        {/* items on the right */}
        <div className='flex gap-2 items-center px-2'>
          <Button
            size='xs'
            color='white'
            endIcon={<SmallFolderIcon />}
            loading={downloadState === 'PENDING'}
            onClick={handleDownloadDoc}
          >
            Download/{formatFileSize((metadata?.size as number) || 0)}
          </Button>
          <Permission permissions={[PermissionNames['Can Manage Fund']]}>
            <Button
              size='xs'
              color='plain'
              className='bg-transparent border-none'
              startIcon={<EditIcon />}
              onClick={toggleModal}
            ></Button>
          </Permission>
          <Permission permissions={[PermissionNames['Can Manage Fund']]}>
            <Button
              size='xs'
              color='lightred'
              className='!p-1'
              startIcon={<RedBinIcon />}
              onClick={toggleModal2}
            />
          </Permission>
        </div>
      </div>
      {/* Rename file modal */}
      <Modal
        ref={modalRef}
        enableWarning
        showCloseBtn
        modalClass='w-[488px]  flex flex-col items-start justify-start h-[420px]'
        title='File Option'
        subtitle='Rename the file in the data room'
      >
        <div className='flex flex-col gap-28 w-full'>
          <div className='flex gap-2 flex-col'>
            <Typography label='sm'>File Name</Typography>

            <TextInput
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder='Input file name'
              className='w-full bg-black-white-shades-1'
            />
          </div>
          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              className='w-[149px]'
              onClick={handleUpdateDoc}
              loading={isUpdateDocLoading}
            >
              Save
            </Button>
            <Button color='white' className='w-[149px]' onClick={toggleModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Remove file modal */}
      <Modal
        ref={modalRef2}
        enableWarning
        showCloseBtn
        modalClass='w-[488px]  flex flex-col items-start justify-start h-[420px]'
        title='File Option'
        subtitle='Delete file in the data room'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <Typography heading='xs'>Are you sure?</Typography>
          <span className='flex gap-1'>
            <Typography label='sm' className='!font-normal'>
              Delete file
            </Typography>
            <Typography
              label='sm'
              className='!font-medium'
            >{`${fileName}`}</Typography>
            <Typography label='sm' className='!font-normal'>
              permanently
            </Typography>
          </span>

          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              loading={isDeleteLoading}
              onClick={handleDeleteDoc}
            >
              Yes, I’m Sure
            </Button>
            <Button color='white' className='w-[124px]' onClick={toggleModal2}>
              No
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default File
