export const currencyFormatter = (number: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  })
  return formatter.format(number)
}
export const numberFormatter = (number: number, approx: number = 3) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumSignificantDigits: approx,
  })
  return formatter.format(number)
}

export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max)
}

export const formatFileSize = (bytes: number, decimals = 2) => {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024
  }

  return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

export function flattenNumber(num: number) {
  // Determine the absolute value of the number
  let absNum = Math.abs(num)

  // Define number suffixes and their corresponding values
  const suffixes = [
    { value: 1e12, suffix: 'T' }, // Thousand
    { value: 1e9, suffix: 'B' }, // Billion
    { value: 1e6, suffix: 'M' }, // Million
    { value: 1e3, suffix: 'K' }, // Thousand
  ]

  // Iterate through the suffixes and apply the appropriate one
  for (let i = 0; i < suffixes.length; i++) {
    if (absNum >= suffixes[i].value) {
      return (num / suffixes[i].value).toFixed(1) + suffixes[i].suffix
    }
  }

  // If the number is less than a thousand, return it as is
  return num.toString()
}

export const removeCommasInNumbers = (input: string): number => {
  const cleanedInput = input.replace(/,/g, '')
  const number = parseFloat(cleanedInput)
  return number
}
