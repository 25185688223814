import { ReactComponent as PdfIcon } from '@/assets/icons/media/pdf.svg'
import { ReactComponent as WordIcon } from '@/assets/icons/media/docx.svg'
import { ReactComponent as ExcelIcon } from '@/assets/icons/media/excel.svg'
import { ReactComponent as RedBinIcon } from '@/assets/icons/utils/redBin.svg'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { formatFileSize } from '@/helpers/numberFormatter'
import ProgressBar from '@/common/ProgressBar'
import { useCreateDocument } from '@/store/dataRoomStore'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { CREATE_DOCUMENT, GET_FILES } from '@/apis/endpoints'
import { ToastNotify } from '@/common/toastManager'

import { useLocation } from 'react-router'
import { useRef, useState } from 'react'
import { CanceledError, Canceler } from 'axios'

import { CreateFolderReqDTO } from '@/apis/dataRoomApis'
import { PutFilesToStore } from '@/apis/profileApis'

interface Prop {
  file: File
  handleRemoveFile: (file: File) => void
  invalidateKey?: string
  isInsideFolder?: boolean
}
function UploadingFile({
  file,
  handleRemoveFile,
  invalidateKey = '',
  isInsideFolder = false,
}: Prop) {
  const { state } = useLocation()
  const [progress, setprogress] = useState({
    percentage: 0,
    loaded: 0,
    total: file.size,
  })
  const [isUploading, setisUploading] = useState(false)
  const cancelerRef = useRef<any>(null)

  let fileIcon: JSX.Element = <></>
  let fileExtention = ''
  switch (file.type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      fileIcon = <WordIcon />
      fileExtention = 'docx'
      break
    case 'application/msword':
      fileIcon = <WordIcon />
      fileExtention = 'doc'
      break
    case 'application/pdf':
      fileIcon = <PdfIcon />
      fileExtention = 'pdf'
      break

    default:
      fileIcon = <ExcelIcon />
      fileExtention = 'xlsx'
      break
  }

  //create doc
  const {
    isLoading: isCreateDocLoading,
    mutateAsync: CreateDoc,
    isError: isCreateDocError,
  } = useCreateDocument([GET_FILES, CREATE_DOCUMENT, invalidateKey])

  const handleCreateDoc = async () => {
    setisUploading(true)
    let res: any[]
    try {
      res = await PutFilesToStore(
        {
          files: [file],
          module_name: 'fund',
        },
        [
          {
            onUploadProgress: (progressEvent: any) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setprogress({
                percentage: progress,
                loaded: progressEvent.loaded,
                total: progressEvent.total,
              })
            },
            abort: (canceler: Canceler) => {
              cancelerRef.current = { cancel: canceler }
            },
          },
        ]
      )
    } catch (error) {
      if (error instanceof CanceledError) {
        return
      } else {
        return setisUploading(false)
      }
    }

    const { response, error } = await withAsync(() => {
      const data: CreateFolderReqDTO = {
        is_folder: false,
        file: res[0].url,
        name: file.name,
        fund: state.id,
        metadata: {
          size: file.size,
          type: file.type,
        },
      }
      if (isInsideFolder) data.parent = state?.folderId

      return CreateDoc({
        data,
      })
    })

    if (error) {
      if ((error as any)?.message !== 'User Cancelled Request') return
      return errorHandler(error) // Handle other errors
    }
    if (response) {
      handleRemoveFile(file)
      ToastNotify('success', {
        message: 'Success',
      })
      setisUploading(false)
    }
  }
  const handleCancelUpload = () => {
    if (cancelerRef?.current?.cancel) {
      cancelerRef.current.cancel('User Cancelled Request')
    }

    setisUploading(false)
    setprogress({ percentage: 0, loaded: 0, total: file.size })
  }

  const [newFileName, extention] = file.name.split('.')

  return (
    <main>
      <div className='flex w-full flex-col gap-2 justify-between align-center bg-white rounded-md border h-auto p-2 sm:flex-row sm:h-[69px] sm:gap-0'>
        {/* items on the left */}
        <div className='flex gap-2 items-start'>
          {fileIcon}
          <div className='flex flex-col gap-1'>
            <span className='flex items-center'>
              <Typography label='md'>{newFileName}</Typography>
              <Typography label='md' className='!text-black-white-shades-3'>
                .{fileExtention}
              </Typography>
            </span>
            <ProgressBar percentageProgress={progress.percentage} />
            <Typography label='sm' className='!text-black-white-shades-4'>
              {formatFileSize(progress.loaded)}/{formatFileSize(progress.total)}
            </Typography>
          </div>
        </div>

        {/* items on the right */}
        <div className='flex gap-2 items-center'>
          {isUploading ? (
            <Button
              size='xs'
              color='white'
              className=''
              onClick={handleCancelUpload}
            >
              Cancel Upload
            </Button>
          ) : (
            <div className='flex gap-2 items-center'>
              <Button
                size='xs'
                color='white'
                className='px-4'
                onClick={() => handleCreateDoc()}
                loading={isCreateDocLoading || isUploading}
              >
                Upload
              </Button>
              <Button
                size='xs'
                color='lightred'
                className='!px-2'
                startIcon={<RedBinIcon />}
                onClick={() => handleRemoveFile(file)}
              ></Button>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default UploadingFile
