import React, { useState } from 'react'

import { ReactComponent as FolderIcon } from '@/assets/icons/utils/whiteFolder.svg'
import { ReactComponent as ThreeDots } from '@/assets/icons/utils/verticalThreeDots.svg'
import { ReactComponent as LessAndGreaterIcon } from '@/assets/icons/utils/lessAndGreater.svg'
import { ReactComponent as RedBinIcon } from '@/assets/icons/utils/redBin.svg'

import Typography from '@/common/Typography'
import DropdownButton from '@/common/DropdownButton'
import Button from '@/common/button/Button'
import Modal, { RefType } from '@/common/Modal'
import TextInput from '@/common/TextInput'
import { Pages } from '@/routes/route.constant'
import { useLocation, useNavigate, useParams } from 'react-router'
import { GetFoldersResDTO } from '@/apis/dataRoomApis'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import { errorHandler, withAsync } from '@/helpers/withAsync'

import { ToastNotify } from '@/common/toastManager'
import { useDeleteDocument, useUpdateDocument } from '@/store/dataRoomStore'
import { GET_FOLDERS } from '@/apis/endpoints'

import { folderColors } from '../DataRoom'
import Permission from '@/common/permissions/Permission'
import { PermissionNames } from '@/common/permissions/permission.constant'

function FolderComponent({
  color = 'purple',
  name: folderName,
  id,
  file_count,
}: Partial<GetFoldersResDTO>) {
  const navigate = useNavigate()

  const { state } = useLocation()
  const { fundName } = useParams()

  const modalRef = React.useRef<RefType>(null)
  const modalRef1 = React.useRef<RefType>(null)
  const [name, setname] = useState<string>(folderName as string)

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }
  const toggleModal1 = (): void => {
    modalRef1?.current?.handleToggle()
  }

  const handleClickDropdownItem = (clickedItem: string) => {
    switch (clickedItem) {
      case 'delete':
        toggleModal()
        break
      case 'rename':
        toggleModal1()
        break

      default:
        break
    }
  }

  //update doc
  const {
    isLoading: isUpdateDocLoading,
    mutateAsync: UpdateDoc,
    isError: isUpdateDocError,
  } = useUpdateDocument([[GET_FOLDERS]])

  //delete doc
  const {
    isLoading: isDeleteLoading,
    mutateAsync: DeleteFolder,
    isError: isDeleteError,
  } = useDeleteDocument([[GET_FOLDERS]])

  const handleUpdateDoc = async () => {
    const { response, error } = await withAsync(() =>
      UpdateDoc({
        id,
        name,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal1()
    }
  }

  const handleDeleteDoc = async () => {
    const { response, error } = await withAsync(() =>
      DeleteFolder({
        id,
      })
    )

    if (error) return errorHandler(error)
    if (response) {
      ToastNotify('success', {
        message: 'Success',
      })
      toggleModal()
    }
  }
  return (
    <main>
      <div
        className={`w-full h-[140px]  rounded-[8px] p-[16px] flex justify-between relative`}
        style={{
          background: `${folderColors[color || 'purple']}`,
        }}
      >
        <div className='flex gap-2 items-start justify-start'>
          <FolderIcon />
          <div className='flex gap-1 flex-col items-start justify-start'>
            <Typography heading='sm' className='!text-white -m-1 '>
              {truncateText(folderName as string, 8)}
            </Typography>
            <Typography paragraph='md' className='!text-white'>
              {file_count || 0} File{file_count && +file_count > 1 ? 's' : null}
            </Typography>
          </div>
        </div>
        <Permission permissions={[PermissionNames['Can Manage Fund']]}>
          <DropdownButton
            ClickComponent={
              <div className='cursor-pointer p-[5px]  rounded-md bg-tranparent hover:bg-black/[.20] '>
                <ThreeDots />
              </div>
            }
            items={[
              {
                name: (
                  <span className='flex items-center gap-2'>
                    <LessAndGreaterIcon />
                    <Typography
                      label='sm'
                      className='hover:font-medium cursor-pointer'
                    >
                      Rename Folder
                    </Typography>
                  </span>
                ),
                value: 'rename',
              },

              {
                name: (
                  <span className='flex items-center gap-2'>
                    <RedBinIcon />
                    <Typography
                      label='sm'
                      className='!text-[#EA3829] hover:font-medium cursor-pointer'
                    >
                      Delete Folder
                    </Typography>
                  </span>
                ),
                value: 'delete',
              },
            ]}
            onClick={(item: any) => handleClickDropdownItem(item)}
            trayClass='!w-[197px] left-[-160px] !rounded-[8px]'
            itemClass='!border-transparent cursor-default'
          />
        </Permission>
        <span
          className='w-full absolute bottom-0  left-0 flex justify-center items-center h-[56px] bg-black/[.20] rounded-b-[8px] cursor-pointer'
          onClick={() =>
            navigate(`/app/${Pages.Portfolio}/${fundName}/${folderName}`, {
              state: {
                folderId: id,
                id: state.id,
              },
            })
          }
        >
          {' '}
          <Typography label='lg' className='!text-white'>
            Open folder
          </Typography>
        </span>
      </div>
      <Modal
        ref={modalRef}
        enableWarning={false}
        modalClass='w-[373px] pt-0  flex flex-col items-center justify-center'
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <Typography heading='xs'>Are you sure?</Typography>
          <span className='flex gap-1'>
            <Typography label='sm' className='!font-normal'>
              Delete folder
            </Typography>
            <Typography
              label='sm'
              className='!font-medium'
            >{`${folderName}`}</Typography>
            <Typography label='sm' className='!font-normal'>
              permanently
            </Typography>
          </span>

          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              loading={isDeleteLoading}
              onClick={handleDeleteDoc}
            >
              Yes, I’m Sure
            </Button>
            <Button color='white' className='w-[124px]' onClick={toggleModal}>
              No
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        ref={modalRef1}
        enableWarning
        showCloseBtn
        modalClass='w-[488px] flex flex-col items-start justify-start h-[420px]'
        title='Folder Option'
        subtitle='Rename the folder in the data room'
      >
        <div className='flex flex-col gap-28 w-full'>
          <div className='flex gap-2 flex-col'>
            <Typography label='sm'>Folder Name</Typography>

            <TextInput
              onChange={(e) => setname(e.target.value)}
              value={name}
              placeholder='Input folder name'
              className='w-full bg-black-white-shades-1'
            />
          </div>
          <div className='flex items-center gap-2'>
            <Button
              color='primary'
              className='w-[149px]'
              onClick={handleUpdateDoc}
              loading={isUpdateDocLoading}
            >
              Save
            </Button>
            <Button color='white' className='w-[149px]' onClick={toggleModal1}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  )
}

export default FolderComponent
