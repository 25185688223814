import { FundInfoReqDTO, FundStructure } from '@/apis/authApis'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Button from '@/common/button/Button'
import { FormikHelpers, useFormik } from 'formik'

import { fundSchema } from '../validators/signup.validator'
import iconComponents from '@/assets/icons/iconComponents'

const FundEntityInformation = (props: {
  data: Partial<FundInfoReqDTO>
  onNext: (res: any) => void
}) => {
  const { onNext, data } = props
  const INITIAL_VALUES: FundInfoReqDTO = {
    name: data.name || '',
    email: data.email || '',
    phone_number: data.phone_number || '',
    physical_address: data.physical_address || '',
    registration_id: data.registration_id || '',
    structure: (data.structure || '') as FundStructure,
    website: data.website || '',
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    validationSchema: fundSchema,
  })
  async function submitHandler(
    values: FundInfoReqDTO,
    { setSubmitting, resetForm }: FormikHelpers<FundInfoReqDTO>
  ) {
    onNext(values)
    resetForm()
    setSubmitting(false)
  }
  return (
    <main className='flex flex-col items-start gap-6 self-stretch'>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
      >
        <FormGroup label='Fund manager name' id='name' className='col-span-2'>
          <InputWithAdornment
            placeholder='Enter fund manager name'
            autoComplete='organization'
            onChange={handleChange}
            onBlur={handleBlur}
            name='name'
            value={values.name}
            error={
              errors.name && (touched.name as unknown as string) && errors.name
            }
          />
        </FormGroup>
        <FormGroup
          id='structure'
          label='Structure'
          className='col-span-1 !gap-0'
        >
          <SelectInput
            className='w-full'
            options={Object.keys(FundStructure).sort((a, b) =>
              a.localeCompare(b)
            )}
            name='structure'
            placeholder='Select'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.structure}
            error={
              errors.structure &&
              (touched.structure as unknown as string) &&
              errors.structure
            }
          />
        </FormGroup>
        <FormGroup
          id='registeration_id'
          label='Registeration ID Number'
          className='col-span-1 !gap-0'
        >
          <InputWithAdornment
            name='registration_id'
            placeholder='Enter Registeration Id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.registration_id}
            error={
              errors.registration_id &&
              (touched.registration_id as unknown as string) &&
              errors.registration_id
            }
          />
        </FormGroup>

        <FormGroup
          label='Physical address'
          id='physical_address'
          className='col-span-2'
        >
          <InputWithAdornment
            name='physical_address'
            placeholder='Enter physical Id'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.physical_address}
            error={
              errors.physical_address &&
              (touched.physical_address as unknown as string) &&
              errors.physical_address
            }
          />
        </FormGroup>
        <FormGroup
          label='Organisation email address'
          id='email'
          className='col-span-1'
        >
          <InputWithAdornment
            name='email'
            placeholder='Enter email address'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <FormGroup
          label='Organisation phone number'
          id='phone'
          className='col-span-1'
        >
          <InputWithAdornment
            name='phone_number'
            placeholder='Enter phone number'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone_number}
            error={
              errors.phone_number &&
              (touched.phone_number as unknown as string) &&
              errors.phone_number
            }
          />
        </FormGroup>

        <FormGroup id='website_url' label='Website  URL' className='col-span-2'>
          <InputWithAdornment
            left={<iconComponents.util.GlobalIcon />}
            placeholder='http(s)://'
            inputMode={'url'}
            autoComplete='url'
            onChange={handleChange}
            onBlur={handleBlur}
            name='website'
            value={values.website}
            error={
              errors.website &&
              (touched.website as unknown as string) &&
              errors.website
            }
          />
        </FormGroup>

        <Button
          type='submit'
          color='primary'
          size='xl'
          className='w-full text-center  mt-8 col-span-2'
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          Continue
        </Button>
      </form>
    </main>
  )
}

export default FundEntityInformation
