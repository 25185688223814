import { flushSync } from 'react-dom'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import useQueryParams from '@/hooks/useQueryParams'
import Title from '@/common/Title'
import Typography from '@/common/Typography'
import useStepper from '@/hooks/useStepper'
import { Pages } from '@/routes/route.constant'
import { FundInfoReqDTO } from '@/apis/authApis'

import FundEntityInformation from './components/FundEntityInformation'
import GeneralPartnerInformation from './components/GeneralPartnerInformation'
import { Storage } from '@/helpers/Storage'

const SignUp = () => {
  const totalSteps = 2
  const { page = '1' } = useQueryParams()
  const navigate = useNavigate()
  const {
    step: currentPage,
    goToNextStep: gotoGeneralPartnerInfo,
    goToPrevStep: gotoDetailInfo,
  } = useStepper(Number(page))
  const [storedState, setStoredState] = useState<Partial<FundInfoReqDTO>>(
    Storage.getItem('FUND_INFO') || {}
  )
  const PageComponents = {
    1: {
      title: 'Fund Manager Information',
      component: (
        <FundEntityInformation
          data={storedState}
          onNext={(resp: any) => {
            flushSync(() => {
              gotoGeneralPartnerInfo()
              navigate('.?page=2')
              setStoredState(resp)
              Storage.setItem('FUND_INFO', resp)
            })
          }}
        />
      ),
    },
    2: {
      title: 'General Partner Information',
      component: (
        <GeneralPartnerInformation
          fundData={storedState}
          onPrev={gotoDetailInfo}
          onDone={() => {
            Storage.removeItem('FUND_INFO')
            gotoDetailInfo()
            setStoredState({})
          }}
        />
      ),
    },
  }

  return (
    <article className='w-full flex-1 flex flex-col gap-16 md:px-[92px] md:pt-6 pb-10 overflow-auto '>
      <header className='w-full flex items-center justify-center'>
        <Title>Sign Up</Title>
        <hgroup className='flex flex-col justify-center items-center'>
          <Typography as='h1' heading='xl' className='text-center'>
            Sign Up
          </Typography>
          {/* <Typography
            as='p'
            paragraph='lg'
            className='text-center text-sifuse-shades-500'
          >
            Description text
          </Typography> */}
        </hgroup>
      </header>
      <div className='flex flex-col gap-6'>
        <section className='flex items-center justify-between gap-2 self-stretch w-full'>
          <Typography
            as='h2'
            label='lg'
            className='text-sifuse-shades-900 whitespace-nowrap'
          >
            {PageComponents[currentPage as 1].title}
          </Typography>
          <Typography
            paragraph='lg'
            className='flex w-full justify-end items-center gap-1 text-sifuse-shades-700'
          >
            <strong className='text-sifuse-shades-700 leading-none font-bold'>
              {' '}
              {currentPage}
            </strong>{' '}
            <span className=' leading-6'>/</span>{' '}
            <span className=' leading-6'>{totalSteps}</span>
          </Typography>
        </section>
        {PageComponents[currentPage as 1].component}
      </div>
      <footer className='w-full flex self-stretch items-end justify-center gap-1 flex-1'>
        <Typography
          button='md'
          color='var(--shades500)'
          className='text-sifuse-shades-500'
        >
          Already have an account ?
        </Typography>
        <button
          type='button'
          onClick={() => navigate(`/auth/${Pages.SignIn}`)}
          className='hover:underline font-semibold text-base leading-6 text-sifuse-main-secondary'
        >
          Sign in
        </button>
      </footer>
    </article>
  )
}

export default SignUp
