import React, { useRef } from 'react'
interface Props {
  onFileSelelcted: (
    file: File,
    imgUrl: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void
  btnElement: React.ReactElement
  accept?: string
  multiple?: boolean
  name?: string
}
function FileUploadBtn({
  onFileSelelcted,
  name,
  btnElement,
  multiple = false,
  accept = '.doc,.docx,.xml,.xlsx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      if (multiple) {
        const fileArray = Array.from(files)
        fileArray.forEach((file) => {
          const fileUrl = URL.createObjectURL(file)
          onFileSelelcted(file, fileUrl, event)
        })
      } else {
        const file = files[0]
        const fileUrl = URL.createObjectURL(file)
        onFileSelelcted(file, fileUrl, event)
      }
    }
  }
  return (
    <>
      {React.cloneElement(btnElement, {
        onClick: handleButtonClick,
      })}

      <input
        name={name}
        type={'file'}
        accept={accept}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple={multiple}
      />
    </>
  )
}

export default FileUploadBtn
