/* eslint-disable react-hooks/exhaustive-deps */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import { lazy } from 'react'

import PublicRoutes from '@/routes/PublicRoutes'
import ProtectedRoutes from '@/routes/ProtectedRoutes'
import { Pages } from './route.constant'

import AuthenticationLayout from '@/layouts/auth'
import SuccessLayout from '@/layouts/success'
import ApplicationLayout from '@/layouts/app'

import Timeline from '@/pages/theNetwork/Timeline'
import MyNetwork from '@/pages/theNetwork/MyNetwork'
import SingleStartup from '@/pages/theNetwork/SingleStartup'
import SingleUser from '@/pages/theNetwork/SingleUser'
import TeamInfo from '@/pages/theNetwork/TeamInfo'

import Startups from '@/pages/theNetwork/Startups'

import SignUp from '@/pages/auth/SignUp'
import SignIn from '@/pages/auth/SignIn'
import ChangePassword from '@/pages/auth/ChangePassword'
import Setup from '@/pages/auth/Setup'
import JoinTeam from '@/pages/auth/JoinTeam'
import VerifyAccount from '@/pages/auth/VerifyAccount'

import File from '@/pages/auth/File'
import PublicFolder from '@/pages/auth/Folder'
import NotFound from '@/pages/error/NotFound'
import AccessDenied from '@/pages/error/AccessDenied'
import SomethingWentWrong from '@/pages/error/SomethingWentWrong'

const InvestorManagement = lazy(() => import('@/pages/investorMgt/index'))
const SingleGroup = lazy(() => import('@/pages/theNetwork/SingleGroup'))
const SingleFund = lazy(() => import('@/pages/theNetwork/SingleFund'))

const NetworkInvestors = lazy(() => import('@/pages/theNetwork/Investors'))
const GroupTeamMembers = lazy(
  () => import('@/pages/theNetwork/GroupTeamMembers')
)
const FundDashboard = lazy(() => import('@/pages/dashboard/index'))

const Profile = lazy(() => import('@/pages/profile'))

const EventsPage = lazy(() => import('@/pages/events'))
const EventDetails = lazy(() => import('@/pages/events/EventDetails'))

const Messages = lazy(() => import('@/pages/message'))

const Notifications = lazy(() => import('@/pages/notification'))
const Communities = lazy(() => import('@/pages/community'))
const Explore = lazy(() => import('@/pages/community/Explore'))
const SingleCommunity = lazy(() => import('@/pages/community/SingleCommunity'))
const CommunityGroup = lazy(() => import('@/pages/community/Group'))
const AllGroups = lazy(() => import('@/pages/community/AllGroups'))

const PortfolioFundFolder = lazy(() => import('@/pages/portfolio/Folder'))
const PortfolioPage = lazy(() => import('@/pages/portfolio'))
const FundDetailsPage = lazy(() => import('@/pages/portfolio/FundDetails'))
const OrganisationProfile = lazy(() => import('@/pages/organisationprofile'))
const Funds = lazy(() => import('@/pages/fund'))
const OrganizationFundDetails = lazy(() => import('@/pages/fund/FundDetails'))
const BusinessDetails = lazy(() => import('@/pages/fund/BusinessDetails'))

const DealFlow = lazy(() => import('@/pages/dealFlow'))
const DealFlowScreeningCriteria = lazy(
  () => import('@/pages/dealFlow/ScreeningCriteria')
)
const ScreenedStartups = lazy(() => import('@/pages/dealFlow/ScreenedStartups'))
const DataRoom = lazy(() => import('@/pages/dealFlow/dataRoom/index'))
const Folder = lazy(() => import('@/pages/dealFlow/dataRoom/Folder'))

const StartupDiscovery = lazy(() => import('@/pages/startupdiscovery'))
const ScreeningCriteria = lazy(
  () => import('@/pages/startupdiscovery/ScreeningCriteria')
)
const DiscoveryFolder = lazy(
  () => import('@/pages/startupdiscovery/details/Folder')
)
const DiscoveryDetails = lazy(() => import('@/pages/startupdiscovery/details'))
const AllStartup = lazy(() => import('@/pages/startupdiscovery/AllStartup'))
const InterestedStartups = lazy(
  () => import('@/pages/startupdiscovery/InterestedStartups')
)
const TeamDetails = lazy(
  () => import('@/pages/startupdiscovery/details/TeamDetails')
)

const UsersRoles = lazy(() => import('@/pages/usersAndRoles'))

const CatchAllAuthRoute = () => {
  return <Navigate to={`/auth/${Pages.SignIn}`} />
}

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<SomethingWentWrong />}>
      <Route element={<PublicRoutes />}>
        <Route path='/auth' element={<AuthenticationLayout />}>
          <Route index element={<Navigate to={`/auth/${Pages.SignIn}`} />} />
          <Route path={Pages.SignIn} element={<SignIn />} />
          <Route path={Pages.SignUp} element={<SignUp />} />
          <Route path={`${Pages.File}/:fileName/:id`} element={<File />} />
          <Route
            path={`${Pages.Folder}/:folderName/:id`}
            element={<PublicFolder />}
          />
          <Route path={Pages.ChangePassword} element={<ChangePassword />} />
          <Route path={Pages.VerifyAccount} element={<VerifyAccount />} />
          <Route path={Pages.JoinTeam} element={<JoinTeam />} />
        </Route>
        <Route path='*' element={<CatchAllAuthRoute />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<ProtectedRoutes />}>
          <Route path='/success' element={<SuccessLayout />}>
            <Route index element={<Setup />} />
          </Route>
          <Route path='/app' element={<ApplicationLayout />}>
            <Route index element={<Navigate to={Pages.Dashboard} />} />
            <Route path={Pages.Dashboard} element={<FundDashboard />} />
            <Route path={Pages.StartupDiscovery}>
              <Route index element={<StartupDiscovery />} />
              <Route path={':startup'}>
                <Route index element={<DiscoveryDetails />} />
                <Route path='team-info' element={<TeamDetails />} />
                <Route path=':folderName' element={<DiscoveryFolder />} />
              </Route>
              <Route
                path={Pages.ScreeningCriteria}
                element={<ScreeningCriteria />}
              />
              <Route path={Pages.ExploreAll} element={<AllStartup />} />
              <Route
                path={Pages.InterestedStartup}
                element={<InterestedStartups />}
              />
            </Route>
            <Route path={Pages.Fund}>
              <Route index element={<Funds />} />
              <Route path=':fundName'>
                <Route index element={<OrganizationFundDetails />} />
                <Route path=':businessName' element={<BusinessDetails />} />
              </Route>
            </Route>
            <Route
              path={Pages.OrganisationProfile}
              element={<OrganisationProfile />}
            />
            <Route path={Pages.Network}>
              <Route index element={<Timeline />} />
              <Route path={Pages.MyNetwork} element={<MyNetwork />} />
              <Route path={Pages.AllPeople} element={<NetworkInvestors />} />

              <Route
                path={`${Pages.User}/:username`}
                element={<SingleUser />}
              />
              <Route
                path={`${Pages.Fund}/:fundname`}
                element={<SingleFund />}
              />

              <Route path={`${Pages.Group}/:groupname`}>
                <Route index element={<SingleGroup />} />
                <Route
                  path={`${Pages.TeamInfo}`}
                  element={<GroupTeamMembers />}
                />
              </Route>

              <Route path={Pages.Startups}>
                <Route index element={<Startups />} />
                <Route path=':startupName' element={<SingleStartup />} />
                <Route
                  path={`:startupName/${Pages.TeamInfo}`}
                  element={<TeamInfo />}
                />
              </Route>
            </Route>

            <Route path={Pages.Event}>
              <Route index element={<EventsPage />} />
              <Route path=':eventName' element={<EventDetails />} />
            </Route>

            <Route path='events' element={<EventsPage />} />

            <Route
              path={Pages.InvestorManagement}
              element={<InvestorManagement />}
            />
            <Route path={Pages.DealFlow}>
              <Route index element={<DealFlow />} />
              <Route
                path={`${Pages.ScreeningCriteria}`}
                element={<DealFlowScreeningCriteria />}
              />
              <Route
                path={`${Pages.ScreenedStartups}`}
                element={<ScreenedStartups />}
              />
              <Route path={`${Pages.DataRoom}/:startupName`}>
                <Route index element={<DataRoom />} />
                <Route element={<Folder />} path={':folderName'} />
              </Route>
            </Route>
            <Route path={Pages.Portfolio}>
              <Route index element={<PortfolioPage />} />
              <Route path=':fundName'>
                <Route index element={<FundDetailsPage />} />
                <Route path=':folderName' element={<PortfolioFundFolder />} />
              </Route>
            </Route>

            <Route path={Pages.Profile} element={<Profile />} />

            <Route path={Pages.Message} element={<Messages />} />
            <Route path={Pages.Notification} element={<Notifications />} />
            <Route path={Pages.UsersRoles} element={<UsersRoles />} />
            <Route path={Pages.Community}>
              <Route index element={<Communities />} />
              <Route
                path={`${Pages.Explore}/${Pages.AllCommunities}`}
                element={<Explore />}
              />
              <Route path={':communityName'}>
                <Route index element={<SingleCommunity />} />
                <Route path={Pages.CommunityGroup}>
                  <Route index element={<AllGroups />} />
                  <Route path={':groupName'} element={<CommunityGroup />} />
                </Route>
              </Route>
            </Route>
            <Route path={Pages.AccessDenied} element={<AccessDenied />} />
            <Route
              path={Pages.SomethingWentWrong}
              element={<SomethingWentWrong />}
            />
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Route>
  )
)

export default RouteConfig
