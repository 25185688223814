import { PaginationReq } from '@/apis/api.types'
import {
  CONNECTIONS,
  GET_STARTUPS,
  GET_STARTUPS_HIGHLIGHTS,
  GET_STARTUPS_THAT_MATCH_CRITERIA,
  STARTUPS,
  STARTUP_ASSESSMENT,
  STARTUP_ASSESSMENT_DATA,
} from '@/apis/endpoints'
import {
  CreateAssessment,
  CreateConnection,
  GetAssessment,
  GetAssessmentData,
  Hightlights,
  ModifyInterest,
  StartupDetail,
  Startups,
  StartupsThatMatchCriteria,
  UpdateAssessment,
} from '@/apis/startupApis'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetStartups = (
  data: PaginationReq & { data_room_access?: boolean | null }
) => {
  return useInfiniteQuery(
    [GET_STARTUPS, data],
    ({ pageParam = 1 }) => Startups({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
    }
  )
}
export const useGetStartupsMatch = (data: PaginationReq) => {
  return useInfiniteQuery(
    [GET_STARTUPS_THAT_MATCH_CRITERIA, data],
    ({ pageParam = 1 }) =>
      StartupsThatMatchCriteria({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage, allPages) {
        if (!lastPage.next) return undefined
        const page = new URL(String(lastPage.next)).searchParams.get('page')
        return page
      },
      getPreviousPageParam: (firstPage, allPages) => {
        if (!firstPage.previous) return undefined
        const page = new URL(String(firstPage.previous)).searchParams.get(
          'page'
        )
        return page
      },
    }
  )
}

export const useGetStartup = (startup: number) => {
  return useQuery([GET_STARTUPS, startup], () => StartupDetail({ startup }), {
    enabled: !!startup,
  })
}

export const useGetHighlights = (startup: number) => {
  return useQuery(
    [GET_STARTUPS_HIGHLIGHTS, startup],
    () => Hightlights({ startup }),
    {
      enabled: !!startup,
    }
  )
}

export const useCreateConnection = () => {
  const qc = useQueryClient()
  return useMutation(CreateConnection, {
    onSuccess() {
      qc.invalidateQueries([CONNECTIONS])
      qc.invalidateQueries([GET_STARTUPS])
    },
  })
}
export const useModifyInterest = () => {
  const qc = useQueryClient()
  return useMutation(ModifyInterest, {
    onSuccess(res, variables) {
      qc.invalidateQueries([CONNECTIONS])
      qc.invalidateQueries([GET_STARTUPS, variables.startup])
    },
  })
}

export const useGetAssessmentData = () => {
  return useQuery([STARTUP_ASSESSMENT_DATA], GetAssessmentData)
}
export const useGetAssessment = (assessment: number) => {
  return useQuery(
    [STARTUP_ASSESSMENT, assessment],
    () => GetAssessment({ assessment }),
    { enabled: !!assessment }
  )
}
export const useCreateAssessment = () => {
  const qc = useQueryClient()
  return useMutation(CreateAssessment, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS])
    },
  })
}

export const useUpdateAssessment = () => {
  const qc = useQueryClient()
  return useMutation(UpdateAssessment, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS])
      qc.invalidateQueries([STARTUP_ASSESSMENT, variables.id])
    },
  })
}

// import { PaginationReq } from '@/apis/api.types'
// import {
//   CONNECTIONS,
//   GET_STARTUPS,
//   GET_STARTUPS_HIGHLIGHTS,
//   GET_STARTUPS_THAT_MATCH_CRITERIA,
//   STARTUPS,
//   STARTUP_ASSESSMENT,
//   STARTUP_ASSESSMENT_DATA,
// } from '@/apis/endpoints'
// import {
//   CreateAssessment,
//   CreateConnection,
//   GetAssessment,
//   GetAssessmentData,
//   Hightlights,
//   ModifyInterest,
//   StartupDetail,
//   Startups,
//   StartupsThatMatchCriteria,
//   UpdateAssessment,
// } from '@/apis/startupApis'
// import {
//   useInfiniteQuery,
//   useMutation,
//   useQuery,
//   useQueryClient,
// } from 'react-query'

// export const useGetStartups = (data: PaginationReq) => {
//   return useInfiniteQuery(
//     [GET_STARTUPS, data],
//     ({ pageParam = 1 }) => Startups({ ...data, page: pageParam }),
//     {
//       getNextPageParam(lastPage, allPages) {
//         if (!lastPage.next) return undefined
//         const page = new URL(String(lastPage.next)).searchParams.get('page')
//         return page
//       },
//       getPreviousPageParam: (firstPage, allPages) => {
//         if (!firstPage.previous) return undefined
//         const page = new URL(String(firstPage.previous)).searchParams.get(
//           'page'
//         )
//         return page
//       },
//     }
//   )
// }
// export const useGetStartupsMatch = (data: PaginationReq) => {
//   return useInfiniteQuery(
//     [GET_STARTUPS_THAT_MATCH_CRITERIA, data],
//     ({ pageParam = 1 }) =>
//       StartupsThatMatchCriteria({ ...data, page: pageParam }),
//     {
//       getNextPageParam(lastPage, allPages) {
//         if (!lastPage.next) return undefined
//         const page = new URL(String(lastPage.next)).searchParams.get('page')
//         return page
//       },
//       getPreviousPageParam: (firstPage, allPages) => {
//         if (!firstPage.previous) return undefined
//         const page = new URL(String(firstPage.previous)).searchParams.get(
//           'page'
//         )
//         return page
//       },
//     }
//   )
// }

// export const useGetStartup = (startup: number) => {
//   return useQuery([GET_STARTUPS, startup], () => StartupDetail({ startup }), {
//     enabled: !!startup,
//   })
// }

// export const useGetHighlights = (startup: number) => {
//   return useQuery(
//     [GET_STARTUPS_HIGHLIGHTS, startup],
//     () => Hightlights({ startup }),
//     {
//       enabled: !!startup,
//     }
//   )
// }

// export const useCreateConnection = () => {
//   const qc = useQueryClient()
//   return useMutation(CreateConnection, {
//     onSuccess() {
//       qc.invalidateQueries([CONNECTIONS])
//       qc.invalidateQueries([GET_STARTUPS])
//     },
//   })
// }
// export const useModifyInterest = () => {
//   const qc = useQueryClient()
//   return useMutation(ModifyInterest, {
//     onSuccess(res, variables) {
//       qc.invalidateQueries([CONNECTIONS])
//       qc.invalidateQueries([GET_STARTUPS, variables.startup])
//     },
//   })
// }

// export const useGetAssessmentData = () => {
//   return useQuery([STARTUP_ASSESSMENT_DATA], GetAssessmentData)
// }
// export const useGetAssessment = (assessment: number) => {
//   return useQuery(
//     [STARTUP_ASSESSMENT, assessment],
//     () => GetAssessment({ assessment }),
//     { enabled: !!assessment }
//   )
// }
// export const useCreateAssessment = () => {
//   const qc = useQueryClient()
//   return useMutation(CreateAssessment, {
//     onSuccess(data, variables, context) {
//       qc.invalidateQueries([STARTUPS])
//     },
//   })
// }

// export const useUpdateAssessment = () => {
//   const qc = useQueryClient()
//   return useMutation(UpdateAssessment, {
//     onSuccess(data, variables, context) {
//       qc.invalidateQueries([STARTUPS])
//       qc.invalidateQueries([STARTUP_ASSESSMENT, variables.id])
//     },
//   })
// }
